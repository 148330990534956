$primary: 'primary';
$secondary: 'secondary';
$primary-transparent: 'primary-transparent';
$primary-darker: 'primary-darker';

$themes: (
  light: (
    primary: rgba(245, 244, 243, 1),
    secondary: rgba(72, 85, 100, 1),
    primary-transparent: rgba(245, 244, 243, 0.75),
    primary-darker: rgb(200, 200, 200),
  ),
  dark: (
    primary: rgba(39, 43, 56, 1),
    secondary: rgba(246, 246, 246, 1),
    primary-transparent: rgba(39, 43, 56, 0.75),
    primary-darker: rgba(72, 85, 100, 1),
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function getColor($key) {
  @return map-get($theme-map, $key);
}
