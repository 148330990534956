@import "styles/themes";

.scroll-to-next {
  cursor: pointer;

  .arrow {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 0.5rem + env(safe-area-inset-bottom);

    .scroll-text {
      text-align: center;
      font-size: 0.6rem;
      margin-bottom: -0.125rem;
    }
  }

  .bounce {
    animation: bounce 2s infinite;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-30px);
    }

    60% {
      transform: translateY(-15px);
    }
  }
  @include themed() {
    .fa-chevron-down,
    .scroll-text {
      color: getColor($secondary);
    }
  }
}
