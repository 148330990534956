@import 'styles/themes';

#particles {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;

  div {
    height: 100%;
    width: 100%;
  }

  @include themed() {
    background-color: getColor($primary);
  }
}
