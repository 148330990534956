@import "styles/breakpoints";
@import "styles/themes";

.projects {
  padding-top: 2rem;
  min-height: 100vh;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;

  @include breakpoint-small-down {
    padding-top: 3rem;
  }

  .projects-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .project-item__title {
      flex: 0 1;
    }

    .project-item__desc {
      flex: 1;
      justify-content: center;
    }

    .hover-icon {
      margin: 0 5px;
    }

    .project-item__links {
      justify-content: center;
    }

    @include breakpoint-small-down {
      flex-direction: column;
      align-items: center;
    }
  }
}
