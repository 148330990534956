@import 'styles/breakpoints';
@import 'styles/themes';

$border-radius: 2px;

.project-item {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  border-radius: $border-radius;
  transition: box-shadow 0.25s;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 2px 1px -2px rgba(0, 0, 0, 0.2);
  width: 14rem;
  margin: 1rem 1rem;
  word-wrap: break-word;

  @include breakpoint-small-down {
    width: 15rem;
  }

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }

  &__title {
    flex: 1;
    padding: 1rem 1.25rem;
    font-weight: 400;
    text-align: center;

    @include breakpoint-small-down {
      font-size: 1.25rem;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.25rem;

    @include breakpoint-small-down {
      font-size: 0.875rem;
    }
  }

  &__icon {
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0 0.25rem;
    }
  }

  &__links {
    padding: 0 0 0.75rem;
    display: flex;
    justify-content: space-around;
    font-size: 0.875rem;
    font-weight: 400;

    a {
      padding-bottom: 0.25rem;
    }
  }

  @include themed() {
    color: getColor($primary);
    background-color: getColor($secondary);
    a {
      color: getColor($primary);
    }

    svg {
      path {
        fill: getColor($primary);
      }
    }
    .hover-link-primary {
      background: linear-gradient(getColor($primary), getColor($primary)) center
        bottom;
    }
  }
}
