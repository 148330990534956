@import 'styles/themes';

.about {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h1 {
    text-align: center;
  }
  p,
  dl {
    font-size: 1.3rem;
    text-align: left;
    margin: 50px 0;
    @include themed() {
      color: getColor($secondary);
    }
  }
  dt {
    margin: 12px 0;
  }

  .smol {
    font-size: 0.7rem;
  }

  a {
    @include themed() {
      color: getColor($secondary);
      text-decoration: underline;
      text-decoration-color: getColor($secondary);
      -webkit-text-decoration-color: getColor($secondary);
    }
    &:visited {
      @include themed() {
        color: getColor($secondary);
      }
    }
  }
}

@media only screen and (max-height: 568px) {
  .about {
    p,
    dl {
      font-size: 1.1rem;
      margin: 20px 0;
    }
  }
}
