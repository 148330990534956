@import "styles/themes";

.home {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .intro {
    font-family: "Reem Kufi", sans-serif;
    font-size: 3.75em;
    font-weight: 600;
  }

  .tagline {
    font-size: 1.5rem;
    margin: 15px 0;
    font-weight: 300;
  }

  /* Grow effect */
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
    &:hover,
    &:focus,
    &:active {
      transform: scale(1.1);
    }
  }

  @include themed() {
    .intro,
    .tagline {
      color: getColor($secondary);
    }

    .Typist .Cursor {
      color: getColor($secondary);
    }
  }
}

.Typist .Cursor {
  display: inline-block;
  opacity: 0.8;
}
.Typist .Cursor--blinking {
  opacity: 0.8;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
