@import 'styles/breakpoints';
@import 'styles/themes';

html {
  font-size: 12px;
  scroll-behavior: smooth;

  @include breakpoint-small-up {
    font-size: 14px;
  }

  @include breakpoint-medium-up {
    font-size: 16px;
  }

  @include breakpoint-large-up {
    font-size: 18px;
  }

  @include breakpoint-xxlarge-up {
    font-size: 20px;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-weight: 300;
}

h1 {
  font-weight: 900;
  padding: 2rem 0;
  font-size: 2.5rem;

  @include themed() {
    color: getColor($secondary);
  }
}

#main {
  display: block;
  padding: 0 30px;
  text-align: center;

  @include themed() {
    color: getColor($secondary);
  }
}

.content-grid {
  margin: 0 auto;
  width: calc(100% - 2.5rem); // mobile

  @include breakpoint-medium-up {
    width: calc(100% - 5rem);
  }

  @include breakpoint-large-up {
    width: calc(100% - 10rem);
  }

  @include breakpoint-xlarge-up {
    width: calc(100% - 20rem);
  }
}

.layout-card {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

.highlight {
  @include themed() {
    background-color: getColor($primary-darker);
  }
}

::selection {
  @include themed() {
    background: getColor($primary-darker);
  }
}

.hover-link-primary,
.hover-link-secondary {
  display: inline-block !important;
  /*the space for the gradient*/
  padding-bottom: 5px !important;
  background-size: 0% 2px !important;
  /* Don't repeat !!*/
  background-repeat: no-repeat !important;
  transition: all 0.25s !important;
  &:hover {
    background-size: 100% 2px !important;
  }
}
