@import 'styles/themes';

.icons-social {
  font-size: 3rem;
  a {
    padding: 10px;
  }
  @include themed() {
    a {
      color: getColor($secondary);

      svg path {
        fill: getColor($secondary);
      }
    }
  }
}
