@import 'styles/breakpoints';
@import 'styles/themes';

nav {
  position: fixed;
  padding: 0 30px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .menu {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
  }

  .navlink {
    padding: 0.25rem 0;
    margin: 0 1rem;
    font-size: 1.25rem;
    font-weight: 400;
  }

  .themebtn-wrapper {
    cursor: pointer;
    width: 100px;
  }

  .themebtn {
    font-size: 1.25rem;
  }

  .theme-text {
    text-align: center;
    font-size: 0.5rem;
    padding-top: 0.25rem;
  }

  @include breakpoint-small-down {
    height: 4rem;
  }

  @include themed() {
    background: getColor($primary-transparent);

    a {
      color: getColor($secondary);
    }

    .themebtn {
      color: getColor($secondary);
    }

    .hover-link-secondary {
      background: linear-gradient(getColor($secondary), getColor($secondary))
        center bottom;
    }
    .theme-text {
      color: getColor($secondary);
    }
  }
}
